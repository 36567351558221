import {ActionReducerMapBuilder} from '@reduxjs/toolkit';

import {clientApi} from '@store/client/api';
import {clientStorageApi} from '@store/client/apiStorage';
import {initialState, State} from '@store/client/slice';
import {addFiles, blockClient, clearClient, clearFiles} from '@store/client/actions';

import {convertDateToStringWithTimeZone, DATE_FORMAT, TIME_FORMAT} from '@utils/date';
import {formatFileSize} from '@utils/calculations';

export const clientReducer = (builder: ActionReducerMapBuilder<State>) =>
  builder
    .addCase(clearFiles, state => {
      state.files = initialState.files;
    })
    .addCase(clearClient, state => {
      state.info = initialState.info;
    })
    .addCase(addFiles, ({files}, {payload}) => {
      payload.forEach(payloadFile => {
        const convertedDateWithTimeZone = convertDateToStringWithTimeZone(
          payloadFile.date,
          DATE_FORMAT,
        );

        const newDataFile = {
          date: convertDateToStringWithTimeZone(payloadFile.date, TIME_FORMAT),
          name: payloadFile.name,
          size: formatFileSize(payloadFile.size),
          url: payloadFile.url,
          owner: payloadFile.owner,
        };

        const foundFileGroup = files.find(file => file.date === convertedDateWithTimeZone);

        if (foundFileGroup) {
          foundFileGroup?.files?.push(newDataFile);
        } else {
          files.push({
            date: convertedDateWithTimeZone,
            files: [newDataFile],
          });
        }
      });
    })
    .addCase(blockClient, ({info}, {payload}) => {
      info.blockDescription = payload.blockDescription;
      info.isBlocked = true;
    })
    .addMatcher(clientStorageApi.endpoints?.getFiles.matchFulfilled, ({files}, {payload}) => {
      //Преобразование формата из getFilesResponse[] в ClientFile[]
      payload.forEach(incomingFile => {
        const convertedDateWithTimeZone = convertDateToStringWithTimeZone(
          incomingFile.date,
          DATE_FORMAT,
        );

        if (!files.find(({date}) => date === convertedDateWithTimeZone)) {
          files.push({
            date: convertedDateWithTimeZone,
            files: [],
          });
        }

        files.find(fileGroup => {
          if (fileGroup.date === convertedDateWithTimeZone) {
            fileGroup?.files?.push({
              date: convertDateToStringWithTimeZone(incomingFile.date, TIME_FORMAT),
              name: incomingFile.name,
              size: formatFileSize(incomingFile.size),
              url: incomingFile.url,
              owner: incomingFile.owner,
            });
          }
        });
      });
    })
    .addMatcher(clientApi.endpoints?.getClient.matchFulfilled, (state, {payload}) => {
      state.info.id = payload.id;
      state.info.phoneNumber = payload.phone_number;
      state.info.firstName = payload.name ?? '';
      state.info.middleName = payload.middle_name ?? '';
      state.info.lastName = payload.last_name ?? '';
      state.info.email = payload.email ?? '';
      state.info.companyName = payload.legal_entity_name;
      state.info.accountNumber = payload.account ?? '';
      state.info.address = payload.address;
      state.info.mailingAddress = payload.mailing_address ?? '';
      state.info.employeesCount = payload.employees_count ?? 0;
      state.info.localPaymentNumber = payload.local_payment_number ?? 1;
      state.info.inn = payload.inn ?? '';
      state.photos = payload.photos ?? [];
      state.info.isBlocked = payload.is_blocked;
      state.info.blockDescription = payload.block_description;
    })
    .addMatcher(clientApi.endpoints?.updateClient.matchFulfilled, ({info}, {payload}) => {
      info.phoneNumber = payload.phone_number ?? '';
      info.firstName = payload.name ?? '';
      info.middleName = payload.middle_name ?? '';
      info.lastName = payload.last_name ?? '';
      info.email = payload.email ?? '';
      info.companyName = payload.legal_entity_name ?? '';
      info.accountNumber = payload.account ?? '';
      info.address = payload.address ?? '';
      info.mailingAddress = payload.mailing_address ?? '';
      info.employeesCount = payload.employees_count ?? 0;
    })
    .addMatcher(clientApi.endpoints?.unBlockClient.matchFulfilled, ({info}) => {
      info.blockDescription = undefined;
      info.isBlocked = false;
    });
