import React, {useEffect, useMemo, useState} from 'react';
import {bindActionCreators, Dispatch} from '@reduxjs/toolkit';
import {useSearchParams} from 'react-router-dom';
import {connect} from 'react-redux';

import Input from '@components/Common/Input';
import Button from '@components/Common/Button';

import {useLazyGetApplicationQuery, useLazyGetUserQuery} from '@store/application/api';
import {clearApplication, clearClientApplicationInfo} from '@store/application/actions';
import {clearClient, clearFiles} from '@store/client/actions';
import {useLazyGetClientQuery} from '@store/client/api';
import {useLazyGetFilesQuery} from '@store/client/apiStorage';

import './index.css';

type Search = 'client' | 'application';

interface DispatchProps {
  clearClientApplicationInfo: typeof clearClientApplicationInfo;
  clearApplication: typeof clearApplication;
  clearClient: typeof clearClient;
  clearFiles: typeof clearFiles;
}

interface ClientSearchProps {
  search: Search;
  initialValue?: string;
}

type Props = DispatchProps & ClientSearchProps;

const ClientSearch = ({
  clearClientApplicationInfo,
  clearApplication,
  clearClient,
  clearFiles,
  search,
  initialValue = '',
}: Props) => {
  const [getUser, {isLoading: isLoadingUser}] = useLazyGetUserQuery();
  const [getApplication, {isLoading: isLoadingApplication}] = useLazyGetApplicationQuery();

  const [getClient, {isLoading: isLoadingClient}] = useLazyGetClientQuery();
  const [getFiles, {isLoading: isLoadingFiles}] = useLazyGetFilesQuery();

  const [urlSearch, setUrlSearch] = useSearchParams('');
  const [id, setId] = useState(urlSearch.get('application') || initialValue || '');
  const [disabled, setDisabled] = useState(true);
  const [infoMessage, setInfoMessage] = useState('');

  const onChangeId = (id: string) => {
    setId(id);
    setUrlSearch({application: id});
    setInfoMessage('');
  };

  const fetchUser = () => {
    void (async () => {
      await getUser(id.trim())
        .then(response => {
          if (response?.isSuccess) {
            return;
          }

          if (
            response?.error &&
            (('originalStatus' in response.error && response.error.originalStatus === 404) ||
              ('status' in response.error && response?.error.status === 500))
          ) {
            setInfoMessage('Пользователь с таким id не найден');
            return;
          }

          setInfoMessage('Произошла ошибка. Обновите страницу или попробуйте позже');
        })
        .catch(err => {
          console.log(err);
          setInfoMessage('Произошла ошибка сервера. Обновите страницу или попробуйте позже');
        });
    })();
  };

  const fetchApplication = () => {
    void (async () => {
      await getApplication(id.trim()).catch(err => {
        console.log(err);
        setInfoMessage('Произошла ошибка сервера. Обновите страницу или попробуйте позже');
      });
    })();
  };

  const fetchFiles = () => {
    void (async () => {
      await getFiles(id.trim()).catch(err => {
        console.log(err);
        setInfoMessage(
          'Произошла ошибка сервера при запросе файлов. Обновите страницу или попробуйте позже',
        );
      });
    })();
  };

  const fetchClient = () => {
    void (async () => {
      await getClient(id.trim())
        .then(response => {
          if (response?.isSuccess) {
            return;
          }

          if (
            response?.error &&
            (('originalStatus' in response.error && response.error.originalStatus === 404) ||
              ('status' in response.error && response?.error.status === 500))
          ) {
            setInfoMessage('Пользователь с таким id не найден');
            return;
          }

          setInfoMessage('Произошла ошибка. Обновите страницу или попробуйте позже');
        })
        .catch(err => {
          console.log(err);
          setInfoMessage('Произошла ошибка сервера. Обновите страницу или попробуйте позже');
        });
    })();
  };

  const clearForm = () => {
    if (search === 'client') {
      clearClient();
      clearFiles();
    }
    if (search === 'application') {
      clearApplication();
      clearClientApplicationInfo();
    }
  };

  const clearAll = () => {
    clearForm();
    onChangeId('');
  };

  const fetchData = () => {
    //Очищение прошлых данных
    clearForm();
    if (search === 'client') {
      fetchClient();
      fetchFiles();
    }
    if (search === 'application') {
      fetchApplication();
      fetchUser();
    }
  };

  const isLoading = useMemo(() => {
    return isLoadingUser || isLoadingApplication || isLoadingClient || isLoadingFiles;
  }, [isLoadingApplication, isLoadingClient, isLoadingFiles, isLoadingUser]);

  useEffect(() => {
    if (id.length > 3) {
      setDisabled(false);
      return;
    }

    setDisabled(true);
  }, [id]);

  useEffect(() => {
    if (id) setUrlSearch({application: id});
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <form className="client__search">
        <Input
          value={id}
          onChange={onChangeId}
          hint="id"
          classNameHint="client__input-text"
          placeholder="Введите id пользователя"
        />
        <Button
          text="Получить/Обновить"
          onClick={fetchData}
          disabled={disabled}
          isLoading={isLoading}
          className="client__button__get"
        />
        <Button text="Очистить все" onClick={clearAll} className="client__button__clear" />
      </form>
      {infoMessage && <div className="text__error mt-4">{infoMessage}</div>}
    </>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      clearClientApplicationInfo,
      clearApplication,
      clearFiles,
      clearClient,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ClientSearch);
