import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import {bindActionCreators, Dispatch} from '@reduxjs/toolkit';
import {connect} from 'react-redux';

import Input from '@components/Common/Input';
import Button from '@components/Common/Button';
import {Client} from '@store/client/types';
import {useBlockClientMutation, useUnBlockClientMutation} from '@store/client/api';
import {blockClient} from '@store/client/actions';

import './index.css';

type BlockFormProps = Pick<Client, 'isBlocked' | 'blockDescription' | 'id'>;

interface DispatchProps {
  blockClient: typeof blockClient;
}

type Props = BlockFormProps & DispatchProps;

const BlockForm = ({id: clientId, isBlocked, blockDescription, blockClient}: Props) => {
  const [blockMessage, setBlockMessage] = useState<string>(blockDescription ?? '');
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const [blockClientRequest, {isLoading: isLoadingBlocked}] = useBlockClientMutation();
  const [unBlockClientRequest, {isLoading: isLoadingUnBlocked}] = useUnBlockClientMutation();

  const onChangeBlockMessage = (text: string) => setBlockMessage(text);

  const handleBlock = () => {
    void (async () => {
      const response = await blockClientRequest({clientId, block_description: blockMessage});
      if (!response?.error) {
        blockClient({blockDescription: blockMessage});
      }
    })();
  };

  const handleUnBlock = () => {
    void (async () => {
      await unBlockClientRequest({clientId});
    })();
  };

  useEffect(() => {
    if (isBlocked) return;

    if (blockMessage.length < 256 && blockMessage.length >= 5) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [blockMessage, isBlocked]);

  useEffect(() => {
    if (!blockDescription) {
      setBlockMessage('');
    }
  }, [blockDescription]);

  return (
    <div className="user-info__block-container">
      <Input
        value={isBlocked ? `Причина блокировки: ${blockMessage}` : blockMessage}
        onChange={onChangeBlockMessage}
        placeholder={isBlocked ? '' : 'Введите причину блокировки (обязательно)'}
        hint={isBlocked ? 'Пользователь заблокирован' : 'Причина блокировки'}
        isTextarea
        disabled={isBlocked}
        classNameHint={classNames('user-info__hint', {
          'text-red': isBlocked,
        })}
        className="user-info__textarea"
      />
      {blockMessage.length >= 256 && <div className="text__error">От 5 до 255 символов</div>}
      <Button
        onClick={isBlocked ? handleUnBlock : handleBlock}
        text={isBlocked ? 'Разблокировать' : 'Заблокировать'}
        disabled={isDisabled}
        className="user-info__button"
        isLoading={isLoadingBlocked || isLoadingUnBlocked}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      blockClient,
    },
    dispatch,
  );

export default connect(undefined, mapDispatchToProps)(BlockForm);
