import {createAction} from '@reduxjs/toolkit';

import {GetFilesResponse} from '@store/client/apiStorage';

export const clearClient = createAction('client/clearUser');

export const clearFiles = createAction('client/clearFiles');

export const addFiles = createAction<GetFilesResponse[]>('client/addFile');

type blockClientPayload = {
  blockDescription: string;
};

export const blockClient = createAction<blockClientPayload>('client/blockClient');
