import {GlobalState} from '@store/reducer';

export const selectUser = (state: GlobalState) => state.application.clientApplicationInfo;

export const selectApplicationStatus = (state: GlobalState) => {
  const {rejectReason, statusText} = state.application.application;

  return {rejectReason, statusText};
};

export const selectApplicationEditable = (state: GlobalState) => {
  const {meetingPlace, meetingComment, meetingTime, bankName, bankAccount, bankBic, id, clientId} =
    state.application.application;

  return {meetingPlace, meetingComment, meetingTime, bankName, bankAccount, bankBic, id, clientId};
};

export const selectApplicationId = (state: GlobalState) => state.application.application.id;

export const selectClientId = (state: GlobalState) =>
  state.application.application.clientId || state.application.clientApplicationInfo.id;

export const selectIsLastStatus = (state: GlobalState) =>
  state.application.application.isLastStatus;

export const selectStatus = (state: GlobalState) => state.application.application.status;

export const selectPhotos = (state: GlobalState) => state.application.photos;
