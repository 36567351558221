import {createApi} from '@reduxjs/toolkit/query/react';

import {baseQueryWithLogout} from '@store/api';

export interface GetUserResponse {
  id: string;
  phone_number: string;
  address: string;
  legal_entity_name: string;
  name?: string;
  last_name?: string;
  middle_name?: string;
  email?: string;
  account?: string;
  mailing_address?: string;
  employees_count?: number;
  local_payment_number?: number;
  inn?: string;
  photos?: string[];
  is_blocked?: boolean;
  block_description?: string;
}

export interface ApplicationFetch {
  id: string;
  client_id: string;
  status?: string;
  status_text?: string;
  reject_reason?: string;
  meeting_place?: string;
  meeting_time?: string;
  meeting_comment?: string;
  bank_account?: string;
  bank_bic?: string;
  bank_name?: string;
  is_last_status?: boolean;
}

export interface ClientUpdate {
  phone_number?: string;
  address?: string;
  legal_entity_name?: string;
  name?: string;
  last_name?: string;
  middle_name?: string;
  email?: string;
  account?: string;
  mailing_address?: string;
  employees_count?: number;
  local_payment_number?: number;
  inn?: string;
}

interface ChangeStatusResponse {
  status_text: string;
  status: string;
  is_last_status: boolean;
}

interface ChangeStatusRequest {
  status: 'success' | 'fail';
  client_id: string;
  reject_reason?: string; //При указании status: 'fail' передавать reason
}

export const applicationApi = createApi({
  baseQuery: baseQueryWithLogout,
  reducerPath: 'applicationApi',
  keepUnusedDataFor: 5,
  endpoints: builder => ({
    getUser: builder.query<GetUserResponse, string>({
      query: id => ({
        url: 'clients',
        method: 'GET',
        params: {
          client_id: id,
        },
      }),
    }),
    updateUser: builder.mutation<ClientUpdate, {clientId: string; requestData: ClientUpdate}>({
      query: ({clientId, requestData}) => ({
        url: 'clients',
        method: 'PATCH',
        params: {
          client_id: clientId,
        },
        body: requestData,
      }),
    }),
    getApplication: builder.query<ApplicationFetch, string>({
      query: id => ({
        url: 'clients/applications',
        method: 'GET',
        params: {
          client_id: id,
        },
      }),
    }),
    //TODO: send application id or application id in query params
    updateApplication: builder.mutation<ApplicationFetch, ApplicationFetch>({
      query: app => ({
        url: 'clients/applications',
        method: 'PATCH',
        body: app,
      }),
    }),
    getStatus: builder.query<ChangeStatusResponse, string>({
      query: id => ({
        url: 'clients/status',
        method: 'GET',
        params: {
          client_id: id,
        },
      }),
    }),
    changeStatus: builder.mutation<ChangeStatusResponse, ChangeStatusRequest>({
      query: data => ({
        url: 'clients/status',
        method: 'PATCH',
        body: data,
      }),
    }),
  }),
});

export const {
  useLazyGetUserQuery,
  useLazyGetApplicationQuery,
  useUpdateUserMutation,
  useUpdateApplicationMutation,
  useChangeStatusMutation,
  useGetStatusQuery,
} = applicationApi;
