import {createApi} from '@reduxjs/toolkit/query/react';

import {baseQueryWithLogout} from '@store/api';
import {ClientUpdate, GetUserResponse} from '@store/application/api';

export const clientApi = createApi({
  baseQuery: baseQueryWithLogout,
  reducerPath: 'clientApi',
  keepUnusedDataFor: 5,
  endpoints: builder => ({
    getClient: builder.query<GetUserResponse, string>({
      query: id => ({
        url: 'clients',
        method: 'GET',
        params: {
          client_id: id,
        },
      }),
    }),
    updateClient: builder.mutation<ClientUpdate, {clientId: string; requestData: ClientUpdate}>({
      query: ({clientId, requestData}) => ({
        url: 'clients',
        method: 'PATCH',
        params: {
          client_id: clientId,
        },
        body: requestData,
      }),
    }),
    blockClient: builder.mutation<void, {clientId: string; block_description: string}>({
      query: ({clientId, block_description}) => ({
        url: 'clients/block',
        method: 'PATCH',
        params: {
          client_id: clientId,
        },
        body: {block_description},
      }),
    }),
    unBlockClient: builder.mutation<void, {clientId: string}>({
      query: ({clientId}) => ({
        url: 'clients/unblock',
        method: 'PATCH',
        params: {
          client_id: clientId,
        },
      }),
    }),
  }),
});

export const {
  useLazyGetClientQuery,
  useUpdateClientMutation,
  useBlockClientMutation,
  useUnBlockClientMutation,
} = clientApi;
