import React from 'react';
import {connect} from 'react-redux';

import ClientSearch from '@components/Client/Search';
import UserInfo from '@components/Client/UserInfo';
import ApplicationInfo from '@components/Client/ApplicationInfo';

import {GlobalState} from '@store/reducer';
import {selectUser, selectPhotos, selectApplicationId} from '@store/application/selectors';
import {clientApplicationInfo} from '@store/application/slice';

import './index.css';

interface StateProps {
  client: clientApplicationInfo;
  photos: string[];
  applicationId: string;
}

type Props = StateProps;

const ClientApplication = ({client: {phoneNumber, id}, photos, client, applicationId}: Props) => {
  return (
    <div className="client">
      <div className="client__title mb-3">Получить данные по клиенту и заявке</div>
      <ClientSearch initialValue={id} search="application" />

      <div className="client__container">
        {phoneNumber && (
          <UserInfo actionButtonType="clientApplicationInfo" client={client} photos={photos} />
        )}
        {applicationId && <ApplicationInfo />}
      </div>
    </div>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  client: selectUser(state),
  photos: selectPhotos(state),
  applicationId: selectApplicationId(state),
});

export default connect(mapStateToProps)(ClientApplication);
