import {createSlice} from '@reduxjs/toolkit';

import {applicationReducer} from '@store/application/reducer';
import {Client} from '@store/client/types';

export type clientApplicationInfo = Client;

export interface Application {
  id: string;
  status: string;
  statusText: string;
  rejectReason: string;
  meetingPlace: string;
  meetingTime: string;
  meetingComment: string;
  bankAccount: string;
  bankBic: string;
  bankName: string;
  clientId: string;
  isLastStatus: boolean;
}

export type State = {
  clientApplicationInfo: clientApplicationInfo;
  application: Application;
  photos: string[];
};

export const initialState: State = {
  photos: [],
  clientApplicationInfo: {
    id: '',
    phoneNumber: '',
    companyName: '',
    address: '',
    firstName: '',
    middleName: '',
    inn: '',
    lastName: '',
    email: '',
    accountNumber: '',
    mailingAddress: '',
    employeesCount: 0,
    localPaymentNumber: 1,
  },
  application: {
    status: '',
    id: '',
    clientId: '',
    rejectReason: '',
    meetingComment: '',
    meetingPlace: '',
    meetingTime: '',
    bankAccount: '',
    bankBic: '',
    bankName: '',
    statusText: '',
    isLastStatus: false,
  },
};

const slice = createSlice({
  name: 'application',
  initialState,
  reducers: {},
  extraReducers: applicationReducer,
});

export default slice;
