import {ActionReducerMapBuilder} from '@reduxjs/toolkit';

import {ApplicationFetch, applicationApi} from '@store/application/api';
import {Application, initialState, State} from '@store/application/slice';
import {clearApplication, clearClientApplicationInfo} from '@store/application/actions';

export const applicationReducer = (builder: ActionReducerMapBuilder<State>) =>
  builder
    .addCase(clearClientApplicationInfo, state => {
      state.clientApplicationInfo = initialState.clientApplicationInfo;
    })
    .addCase(clearApplication, state => {
      state.application = initialState.application;
    })
    .addMatcher(applicationApi.endpoints?.getUser.matchFulfilled, (state, {payload}) => {
      state.clientApplicationInfo.id = payload.id;
      state.clientApplicationInfo.phoneNumber = payload.phone_number;
      state.clientApplicationInfo.firstName = payload.name ?? '';
      state.clientApplicationInfo.middleName = payload.middle_name ?? '';
      state.clientApplicationInfo.lastName = payload.last_name ?? '';
      state.clientApplicationInfo.email = payload.email ?? '';
      state.clientApplicationInfo.companyName = payload.legal_entity_name;
      state.clientApplicationInfo.accountNumber = payload.account ?? '';
      state.clientApplicationInfo.address = payload.address;
      state.clientApplicationInfo.mailingAddress = payload.mailing_address ?? '';
      state.clientApplicationInfo.employeesCount = payload.employees_count ?? 0;
      state.clientApplicationInfo.localPaymentNumber = payload.local_payment_number ?? 1;
      state.photos = payload.photos ?? [];
      state.clientApplicationInfo.inn = payload.inn ?? '';
    })
    .addMatcher(
      applicationApi.endpoints?.getApplication.matchFulfilled,
      ({application}, {payload}) => {
        application.id = payload.id;
        application.clientId = payload.client_id;
        application.isLastStatus = payload.is_last_status ?? false;
        application.statusText = payload.status_text ?? '';
        setApplicationData(application, payload);
      },
    )
    .addMatcher(
      applicationApi.endpoints?.updateUser.matchFulfilled,
      ({clientApplicationInfo}, {payload}) => {
        clientApplicationInfo.phoneNumber = payload.phone_number ?? '';
        clientApplicationInfo.firstName = payload.name ?? '';
        clientApplicationInfo.middleName = payload.middle_name ?? '';
        clientApplicationInfo.lastName = payload.last_name ?? '';
        clientApplicationInfo.email = payload.email ?? '';
        clientApplicationInfo.companyName = payload.legal_entity_name ?? '';
        clientApplicationInfo.accountNumber = payload.account ?? '';
        clientApplicationInfo.address = payload.address ?? '';
        clientApplicationInfo.mailingAddress = payload.mailing_address ?? '';
        clientApplicationInfo.employeesCount = payload.employees_count ?? 0;
      },
    )
    .addMatcher(
      applicationApi.endpoints?.changeStatus.matchFulfilled,
      ({application}, {payload}) => {
        application.status = payload.status;
        application.statusText = payload.status_text;
        application.isLastStatus = payload.is_last_status;
      },
    )
    .addMatcher(
      applicationApi.endpoints?.updateApplication.matchFulfilled,
      ({application}, {payload}) => {
        setApplicationData(application, payload);
      },
    )
    .addMatcher(applicationApi.endpoints?.getStatus.matchFulfilled, ({application}, {payload}) => {
      application.status = payload.status;
      application.statusText = payload.status_text;
      application.isLastStatus = payload.is_last_status;
    });

const setApplicationData = (application: Application, payload: ApplicationFetch) => {
  application.status = payload.status ?? '';
  application.rejectReason = payload.reject_reason ?? '';
  application.meetingComment = payload.meeting_comment ?? '';
  application.meetingPlace = payload.meeting_place ?? '';
  application.meetingTime = payload.meeting_time ?? '';
  application.bankAccount = payload.bank_account ?? '';
  application.bankBic = payload.bank_bic ?? '';
  application.bankName = payload.bank_name ?? '';
};
